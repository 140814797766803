/*
 *
 * EmailTemplates
 *
 */

import React from "react"
import { connect } from "react-redux"
import {
	setTitle,
	delayedDispatch,
	setBreadcrumb,
	updateCrumb,
	setLoader,
} from "store/actions"
import { Helmet } from "react-helmet"
import { Icon, Table } from "components"
import { Col, Drawer, Input, notification, Row, Select, TimePicker } from "antd"
import Strings from "utils/strings"
import moment from "moment"

import { API, Endpoints } from "utils/api"

class Shifts extends React.Component<any, any> {
	constructor(props: any) {
		super(props)

		this.state = {
			shifts: [],
			shiftStart: "",
			shiftEnd: "",
		}
	}

	componentDidMount() {
		const { dispatch } = this.props

		dispatch(setTitle(""))

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences",
						},
						{
							text: Strings.settings.shifts,
							icon: "clock1",
						},
					],
				}
			})
		)

		this.getData()
	}

	componentDidUpdate() {
		const { dispatch } = this.props
		dispatch(updateCrumb())
	}

	async getData() {
		const { dispatch } = this.props

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.get({
				url: Endpoints.uriShifts(),
			})

			if (response.ok) {
				const { shifts = [] } = response.data.results || {}
				this.setState({ shifts })
			} else {
				notification.error({
					message: Strings.settings.shifts,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.shifts,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async saveShift() {
		const { tempShift, shiftStart, shiftEnd } = this.state
		const { dispatch } = this.props

		//if (!tempShift) return;

		//if (!this.validShift()) return;

		dispatch(setLoader(true))

		let response: any
		try {
			const request = tempShift?._id ? API.put : API.post
			response = await request({
				url: Endpoints.uriShifts(tempShift?._id || ""),
				data: tempShift?._id
					? {
							name: tempShift.name,
							from: moment(tempShift.from).utc().valueOf(),
							to: moment(tempShift.to).utc().valueOf(),
						}
					: {
							name: `${moment(shiftStart).utc().format("HH:mm")}-${moment(
								shiftEnd
							)
								.utc()
								.format("HH:mm")}`,
							from: moment(shiftStart).utc().valueOf(),
							to: moment(shiftEnd).utc().valueOf(),
						},
			})

			if (response.ok) {
				await this.getData()
				this.setState({ tempShift: null, showDrawer: false })

				notification.success({
					message: Strings.settings.shifts,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.settings.shifts,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.shifts,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async patchShift(Shift: any) {
		const { dispatch } = this.props

		if (!Shift?._id) return

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.patch({
				url: Endpoints.uriShifts(Shift._id),
				data: {
					disabled: !Shift.disabled,
				},
			})

			if (response.ok) {
				await this.getData()

				notification.success({
					message: Strings.settings.shifts,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.settings.shifts,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.shifts,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async deleteShift(Shift: any) {
		const { dispatch } = this.props

		if (!Shift) return

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.delete({
				url: Endpoints.uriShifts(Shift),
			})

			if (response.ok) {
				await this.getData()

				notification.success({
					message: Strings.settings.shifts,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.settings.shifts,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.shifts,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	validShift() {
		const { tempShift } = this.state

		if (!tempShift?.name) {
			notification.error({
				message: Strings.settings.shifts,
				description: Strings.settings.nameRequired,
				placement: "bottomRight",
				duration: 5,
			})
			return false
		}

		return true
	}

	renderTable() {
		const { shifts = [] } = this.state

		return (
			<Table
				title={{
					icon: "clock1",
					title: Strings.settings.shifts,
				}}
				data={shifts}
				columns={[
					{
						Header: Strings.fields.name,
						id: "Shift_name",
						accessor: (row: any) => row.name || "-",
					},
				]}
				fullPage
				isSinglePage
				filterable
				sortable
				noFieldFilter
				add={{
					onClick: () => this.setState({ showDrawer: true }),
				}}
				actions={{
					edit: (row: any) => ({
						onClick: () =>
							this.setState({
								showDrawer: true,
								tempShift: JSON.parse(JSON.stringify(row)),
							}),
					}),
					remove: (row: any) => ({
						onClick: () => this.deleteShift(row._id),
					}),
					toggle: (row: any) => ({
						onChange: () => this.patchShift(row),
						value: !row.disabled,
					}),
				}}
			/>
		)
	}

	renderDrawer() {
		const { showDrawer, tempShift } = this.state
		const { mobile } = this.props

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="clock1" />
						<p>
							{tempShift?._id
								? Strings.settings.editShift
								: Strings.settings.addShift}
						</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.saveShift()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showDrawer: false,
									tempShift: null,
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ tempShift: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		)
	}

	renderDrawerContent() {
		const { tempShift, shiftStart, shiftEnd } = this.state

		return (
			<Row gutter={[0, 10]}>
				{tempShift?._id && (
					<Col xs={24}>
						<label htmlFor="Shift_name" className="InputLabel --label-required">
							{Strings.fields.name}
						</label>
						<Input
							id="Shift_name"
							placeholder={Strings.fields.name}
							value={tempShift?.name || ""}
							onChange={(e: any) => {
								const value = e.target.value

								this.setState((prevState: any) => ({
									tempShift: {
										...prevState.tempShift,
										name: value,
									},
								}))
							}}
						/>
					</Col>
				)}
				<Col xs={24}>
					<label
						htmlFor="upload_modal_shift"
						className="InputLabel --label-required"
					>
						{Strings.fields.shift}
					</label>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
						}}
					>
						<TimePicker
							minuteStep={30}
							value={tempShift ? moment.utc(tempShift?.from) : shiftStart}
							onChange={(localValue: any) => {
								if (tempShift?._id) {
									this.setState((prevState: any) => ({
										tempShift: {
											...prevState.tempShift,
											name: `${moment(localValue).utc().format("HH:mm")}-${moment(
												tempShift.to
											)
												.utc()
												.format("HH:mm")}`,
											from: localValue,
										},
									}))
								} else {
									this.setState({
										shiftStart: localValue,
									})
								}
							}}
							placeholder={Strings.fields.shiftStart}
							format={"HH:mm"}
							style={{ marginRight: "10px", width: "50%" }}
						/>
						<TimePicker
							minuteStep={30}
							value={tempShift ? moment.utc(tempShift?.to) : shiftEnd}
							onChange={(localValue: any) => {
								if (tempShift?._id) {
									this.setState((prevState: any) => ({
										tempShift: {
											...prevState.tempShift,
											name: `${moment(tempShift.from).utc().format("HH:mm")}-${moment(
												localValue
											)
												.utc()
												.format("HH:mm")}`,
											to: localValue,
										},
									}))
								} else {
									this.setState({
										shiftEnd: localValue,
									})
								}
							}}
							placeholder={Strings.fields.shiftEnd}
							format={"HH:mm"}
							style={{ width: "50%" }}
						/>
					</div>
				</Col>
			</Row>
		)
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.shifts}</title>
					<meta
						name="description"
						content={Strings.settings.shiftsDescription}
					/>
				</Helmet>
				{this.renderTable()}
				{this.renderDrawer()}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
})

export default connect(mapStateToProps)(Shifts)
