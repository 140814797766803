/**
 *
 * Collapse
 *
 */

import React, { Component } from "react"
import "./styles.scss"

/* eslint-disable react/prefer-stateless-function */
class Collapse extends Component<any, any> {
	element: HTMLDivElement | null = null
	constructor(props: any) {
		super(props)
		this.state = {
			expandedStyle: {
				height: 0,
				padding: 0,
				margin: 0,
			},
		}
	}

	componentDidUpdate(prevProps: Readonly<any>): void {
		if (prevProps.expanded !== this.props.expanded) {
			setTimeout(() => {
				let parent = this.element?.parentElement

				while (parent) {
					if (
						parent.classList.contains("CollapsedBlock") &&
						parent.style.height !== "0px"
					) {
						// @ts-ignore
						const height = this.expandableContent.scrollHeight
						const value = this.props.expanded ? height : -height
						const total = parent!.scrollHeight + value
						const final = !this.props.expanded ? total : parent!.scrollHeight

						parent!.style.height = final.toString() + "px"
						break
					}
					parent = parent.parentElement
				}
			}, 250)
		}
	}

	handleResize = () => {
		const { x, y, width, height } =
			(this.element?.getBoundingClientRect() as any) || {}
		// Do something with the layout information
		//cellProps.style.height = height;
		this.forceUpdate()
	}

	UNSAFE_componentWillReceiveProps(props: any) {
		if (props.expandedChildren !== this.props.expandedChildren)
			this.handleResize()
		if (props.expanded) {
			this.setState({
				expandedStyle: {
					// @ts-ignore
					height: this.expandableContent.scrollHeight,
					padding: null,
					margin: null,
				},
			})
		} else {
			this.setState({
				expandedStyle: {
					height: 0,
					padding: 0,
					margin: 0,
				},
			})
		}
	}

	render() {
		const {
			children,
			expandedChildren,
			style,
			expandedStyle: extraStyle,
		} = this.props
		const { expandedStyle } = this.state
		return (
			<div style={{ position: "relative" }}>
				<div className="CollapseContainer" style={style}>
					{children}
				</div>
				<div
					ref={(ref) => (this.element = ref)}
					className="CollapsedBlock"
					style={{ ...extraStyle, ...expandedStyle }}
				>
					<div
						// @ts-ignore
						ref={(ref) => (this.expandableContent = ref)}
					>
						{expandedChildren}
					</div>
				</div>
			</div>
		)
	}
}

export default Collapse
