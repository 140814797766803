import React from "react"
import { Empty } from "antd"
import { UseTableInstanceProps } from "react-table"
import { Row, TableRow } from "./row"
import { Header, Footer } from "./"

interface BodyProps extends UseTableInstanceProps<object> {
	paginated?: boolean
	expanded?: boolean
	onRowPress?: Function
	renderEmpty?: React.ReactNode
	renderExpanded?: React.ReactNode
	emptyDescription?: React.ReactNode
	alwaysExpanded?: boolean
}

export class Body extends React.PureComponent<BodyProps | any> {
	get rows() {
		// @ts-ignore
		const { rows, page, paginated } = this.props
		return paginated ? page : rows
	}

	get isEmpty() {
		return this.rows.length === 0
	}

	renderRow = (rowProps: TableRow, index: any) => {
		const {
			prepareRow,
			expanded,
			onRowPress,
			renderExpanded,
			drag,
			onDragEnd,
			alwaysExpanded,
		} = this.props
		prepareRow(rowProps)
		return (
			<Row
				key={`table_row_${rowProps.id}`}
				{...rowProps}
				drag={drag}
				index={index}
				onDragEnd={onDragEnd}
				onPress={onRowPress}
				expandable={expanded}
				renderExpanded={renderExpanded}
				alwaysExpanded={alwaysExpanded}
			/>
		)
	}

	renderContent() {
		const { drag } = this.props
		if (!this.isEmpty)
			return (
				<React.Fragment>
					<Header
						drag={drag}
						headerGroups={this.props.headerGroups}
						resizable={this.props.resizable}
						sortable={this.props.sortable}
					/>
					<div
						style={{
							minHeight:
								this.props.hasFiltration &&
								(this.props.filtrationApi?.defaultValues?.columnSearch ||
									(Array.isArray(this.props.data) &&
										this.props.data.length > 0))
									? "calc(100% - 92px)"
									: "calc(100% - 40px)",
						}}
						className="TableBodyRows"
					>
						{this.rows.map(this.renderRow)}
					</div>
				</React.Fragment>
			)

		const { renderEmpty, emptyDescription } = this.props

		return (
			<div
				className="TableBodyEmpty"
				style={{
					padding: "40px 0",
					height:
						this.props.hasFiltration &&
						(this.props.filtrationApi?.defaultValues?.columnSearch ||
							(Array.isArray(this.props.data) && this.props.data.length > 0))
							? "calc(100% - 55px)"
							: "100%",
					justifyContent: "center",
					display: "flex",
					alignItems: "center",
				}}
			>
				{typeof renderEmpty === "function" ? (
					renderEmpty()
				) : (
					<Empty description={emptyDescription} />
				)}
			</div>
		)
	}

	render() {
		const { getTableBodyProps, isSinglePage, fullPage, data } = this.props

		const style = { overflowY: "auto" } as any

		if (isSinglePage) {
			style.height = "calc(100% - 190px)"
		}

		if (fullPage) {
			style.height = "100%"
		}

		return (
			<div style={{ overflowX: "auto", flex: 1 }}>
				<div
					{...(getTableBodyProps() as any)}
					style={style}
					className={`TableBody`}
				>
					{this.renderContent()}
					{!this.props.noFieldFilter &&
						this.props.hasFiltration &&
						(this.props.filtrationApi?.defaultValues?.columnSearch ||
							(Array.isArray(data) && data.length > 0)) && (
							<Footer
								{...this.props}
								filtrationApi={this.props.filtrationApi}
								headerGroups={this.props.headerGroups}
							/>
						)}
				</div>
			</div>
		)
	}
}
