import React from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { setLoader, setTitle } from "store/actions"
import { Props, State } from "./types"
import { Helmet } from "react-helmet"
import { Table } from "components"
import { notification } from "antd"
import { API, Endpoints } from "utils/api"
import Strings from "utils/strings"

class Businesses extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)

		this.state = {
			businesses: [],
		}
	}

	componentDidMount() {
		const { dispatch } = this.props

		dispatch(setTitle(""))

		this.getData()
	}

	async getData() {
		const { dispatch } = this.props

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.get({
				url: Endpoints.uriBusinesses(),
			})

			if (response.ok) {
				this.setState({ businesses: response.data.results.business || [] })
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.businesses,
				description: response.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})

			console.log("Error", err as string)
		}

		dispatch(setLoader(false))
	}

	async deleteClient(id: string) {
		const { dispatch } = this.props

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.delete({
				url: Endpoints.uriBusinesses(id),
			})

			if (response.ok) {
				await this.getData()

				notification.success({
					message: Strings.sidebar.businesses,
					description: Strings.businesses.deleted,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.businesses,
				description: response.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})

			console.log("Error", err as string)
		}

		dispatch(setLoader(false))
	}

	async patchClient(client: any) {
		const { dispatch } = this.props

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.patch({
				url: Endpoints.uriBusinesses(`${client._id}`),
				data: {
					isActive: !client.isActive,
				},
			})

			if (response.ok) {
				await this.getData()

				notification.success({
					message: Strings.sidebar.businesses,
					description: Strings.businesses.edited,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.sidebar.businesses,
				description: response.data?.message || Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})

			console.log("Error", err as string)
		}

		dispatch(setLoader(false))
	}

	render() {
		const { businesses } = this.state
		const { dispatch } = this.props

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.businesses}</title>
					<meta name="description" content="Description of Businesses" />
				</Helmet>
				<Table
					title={{
						icon: "partner",
						title: Strings.sidebar.businesses,
					}}
					data={businesses}
					columns={[
						{
							Header: Strings.fields.logo,
							id: "photo",
							accessor: (row: any) => row.logo,
							type: "image",
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.name,
						},
					]}
					filterable
					fullPage
					isSinglePage
					sortable
					add={{
						onClick: () => dispatch(push("/businesses/new")),
					}}
					actions={{
						edit: (original: any) => ({
							onClick: () => dispatch(push(`/businesses/${original._id}`)),
						}),
						remove: (original: any) => ({
							onClick: () => this.deleteClient(original._id),
						}),
						toggle: (original: any) => ({
							value: original.isActive,
							onChange: () => this.patchClient(original),
						}),
					}}
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
})

export default connect(mapStateToProps)(Businesses)
