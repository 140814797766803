import React, { Fragment } from "react"
import { connect } from "react-redux"
import {
	delayedDispatch,
	setBreadcrumb,
	setLoader,
	setTitle,
	updateCrumb,
} from "store/actions"
import { push } from "connected-react-router"
import { Helmet } from "react-helmet"
import { ContentWrapper, Map, Table } from "components"
import {
	Button,
	Col,
	DatePicker,
	Input,
	notification,
	Row,
	Select,
	Image,
} from "antd"
import { API, Endpoints } from "utils/api"
import { Duration } from "luxon"
import Axios from "axios"
import Strings from "utils/strings"
import moment from "moment"
import Geocode from "react-geocode"
import "moment/locale/pt"
import "moment/locale/en-gb"
import "moment-timezone"

import "./styles.scss"
import TextArea from "antd/lib/input/TextArea"

class OrderDetail extends React.Component<any, any> {
	searchTimer: any

	constructor(props: any) {
		super(props)
		this.state = {
			hasUnsavedFields: false,
			defaultStatus: [
				"registered",
				"address_confirmation",
				"picking",
				"packing",
				"loading",
				"on_transit",
				"delivered",
			],
			searchAddress: "",
			defaultAddresses: [],
			newAddressSelected: false,
			newAddress: {
				location: {
					lat: null,
					lng: null,
				},
			},
			newCoords: null,
			basePoint: {
				type: "Point",
				coordinates: [],
			},
			newClientId: "",
			selectedBusiness: props.orderFilters?.business ?? null,
			selectedContract: props.orderFilters?.contract?.contract ?? null,
			shifts: [],
		}
	}

	async componentDidMount() {
		const { dispatch, match } = this.props
		const {
			params: { id },
		} = match || {}

		if (id === "new") await this.getShiftsData()

		dispatch(setTitle(""))

		await this.getData()
		this.breadcrumb()
	}

	componentDidUpdate() {
		const { dispatch } = this.props

		dispatch(updateCrumb())
	}

	async getData() {
		const { dispatch, match } = this.props
		const {
			params: { id },
		} = match || {}

		if (id === "new") return

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.get({
				url: Endpoints.uriOrders(id),
			})

			if (response.ok) {
				const { order, orderRoute } = response.data.results || {}
				const defaultAddresses = order.user?.addresses || []
				defaultAddresses.push({
					address: Strings.orders.newAddress,
					_id: "new_address",
				})
				dispatch(setTitle(order.orderId || ""))

				this.setState({
					order,
					orderRoute,
					defaultAddresses,
					...order,
					userAddress: order.userAddress ?? "new_address",
					userInOrder: order.user,
					basePoint: {
						type: "Point",
						coordinates: !!order.userAddress
							? [
									order?.formattedAddress?.location?.lng,
									order?.formattedAddress?.location?.lat,
								]
							: [],
					},
					newAddressSelected: order.userAddress ? false : true,
					serviceType: order?.serviceType,
				})
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})

				dispatch(push("/orders"))
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})

			dispatch(push("/orders"))
		}

		dispatch(setLoader(false))
	}

	async getShiftsData() {
		const { dispatch } = this.props
		const { selectedContract } = this.state

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.get({
				url: `${Endpoints.uriShifts()}${selectedContract}`,
			})

			if (response.ok) {
				const { shifts = [] } = response.data.results || {}
				this.setState({ shifts })
			} else {
				notification.error({
					message: Strings.settings.shifts,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.shifts,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async createNewOrder() {
		const { dispatch } = this.props
		const {
			selectedContract,
			selectedBusiness,
			orderId,
			orderDate,
			deliveryDate,
			newSlot,
			searchAddress,
			newClientName,
			phone,
			email,
			newClientId,
			serviceType,
			totalVolume,
			totalWeight,
			itemsQuantity,
			marketPlaceCode,
			selectedShift,
			lot,
		} = this.state

		const [startTime, endTime] = newSlot ? newSlot?.split("-") : ["", ""]

		let tempOrder = {
			OrderID: orderId,
			OrderDate: orderDate,
			DeliveryDate: deliveryDate,
			From: startTime,
			To: endTime,
			Address: searchAddress,
			ClientName: newClientName,
			Phone: phone || null,
			Email: email || null,
			id: newClientId || null,
			ServiceType: serviceType || null,
			TotalVolume: totalVolume || null,
			TotalWeight: totalWeight || null,
			ItemsQty: itemsQuantity || null,
			MarketPlaceCode: marketPlaceCode || null,
			Lot: lot,
		}

		let data = {
			contract: selectedContract,
			business: selectedBusiness,
			tempOrder,
			shift: selectedShift,
		}

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.post({
				url: Endpoints.uriCreateNewOrder(),
				data,
			})

			if (response.ok) {
				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.orders.edited,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async searchAddress(address: any) {
		const { newAddress } = this.state
		let auxAddress =
			newAddress.postCode && newAddress?.city
				? address + ", " + newAddress?.postCode + ", " + newAddress?.city
				: address
		try {
			Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "")
			Geocode.fromAddress(auxAddress).then(
				(response: any) => {
					const { lat, lng } = response.results[0].geometry.location
					this.setState({
						//basePoint: [lng, lat]
						basePoint: {
							type: "Point",
							coordinates: [lng, lat],
						},
						hasUnsavedFields: true,
					})
				},
				(error: any) => {
					console.error(error)
				}
			)
		} catch (err) {
			console.log("Error retrieving address information")
		}
	}

	async saveOrder() {
		const {
			serviceType,
			orderFlow,
			itemsQuantity,
			totalWeight,
			totalVolume,
			phone,
			userAddress,
			newCoords,
			newAddressSelected,
			newAddress,
			user,
		} = this.state
		const { dispatch, match } = this.props

		if (!this.orderValid()) {
			return
		}

		const body: any = {
			serviceType,
			itemsQuantity,
			totalWeight,
			totalVolume,
			phone,
			userAddress,
			user: user?._id,
			orderFlow: {
				...orderFlow,
				deliveryDate: moment
					.utc(orderFlow.deliveryDate)
					.startOf("day")
					.format("YYYY-MM-DD"),
			},
		}

		if (newAddressSelected) {
			body.newAddress = newAddress
		}

		if (newCoords) {
			body.newCoords = newCoords
		}

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.put({
				url: Endpoints.uriOrders(match.params.id),
				data: body,
			})

			if (response.ok) {
				const { order } = response.data.results || {}

				this.setState({
					order,
					...order,
					newCoords: null,
					newAddressSelected: false,
					newAddress: { location: { lat: null, lng: null } },
					hasUnsavedFields: false,
					defaultAddresses: JSON.parse(
						JSON.stringify(order?.user?.addresses || [])
					),
				})

				notification.success({
					message: Strings.sidebar.orders,
					description: response?.data?.message || Strings.orders.edited,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description: Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})

			dispatch(push("/orders"))
		}

		dispatch(setLoader(false))
	}

	async getCoords() {
		const { newAddress } = this.state

		const headers = {
			"Content-Type": "application/x-www-form-urlencoded",
		}

		const params = {
			key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
			address: `${newAddress.address} ${newAddress.number} ${newAddress.floor}, ${newAddress.postCode} - ${newAddress.city}`,
		}

		const response = await Axios.request({
			method: "get",
			url: "https://maps.googleapis.com/maps/api/geocode/json",
			params,
			headers,
		})

		const { results } = response.data
		if (!results?.length) return null

		const address = results[0]

		if (address?.geometry?.location) {
			this.setState((prevState: any) => ({
				newAddress: {
					...prevState.newAddress,
					location: address?.geometry?.location,
				},
			}))
		}
	}

	async getAddress() {
		const { newAddress } = this.state

		const headers = {
			"Content-Type": "application/x-www-form-urlencoded",
		}

		const params = {
			key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
			latlng: `${newAddress.location.lat},${newAddress.location.lng}`,
		}

		const response = await Axios.request({
			method: "get",
			url: "https://maps.googleapis.com/maps/api/geocode/json",
			params,
			headers,
		})

		const { results } = response.data
		if (!results?.length) return null

		const address = results[0]
		const postCode =
			address.address_components.find((add: any) =>
				add.types.includes("postal_code")
			)?.short_name || ""

		if (address?.geometry?.location) {
			const number = address.address_components.find((add: any) =>
				add.types.includes("street_number")
			)?.short_name
			this.setState((prevState: any) => ({
				newAddress: {
					...prevState.newAddress,
					address: `${
						address.address_components.find((add: any) =>
							add.types.includes("route")
						)?.short_name
					}${number ? `, ${number}` : ""}`,
					postCode,
					city:
						address.address_components.find((add: any) =>
							add.types.includes("locality")
						)?.short_name || "",
				},
			}))
		}
	}

	getStatusName(status: string) {
		const statusMap = {
			registered: Strings.orders.registered,
			address_confirmation: Strings.orders.addressConfirmation,
			picking: Strings.orders.picking,
			packing: Strings.orders.packing,
			loading: Strings.orders.readyToLoad,
			aggregation: Strings.orders.aggregation,
			dispatching: "Dispatching",
			on_transit: Strings.orders.on_transit,
			delivered: Strings.orders.delivered,
			not_delivered: Strings.orders.notDelivered,
			cancelled: Strings.orders.cancelled,
			returned: Strings.orders.returned,
		}

		//@ts-ignore
		return statusMap[status] || "-"
	}

	orderValid() {
		const { newAddressSelected, newAddress, userAddress } = this.state

		if (
			newAddressSelected &&
			(!newAddress?.address ||
				!newAddress?.postCode ||
				!newAddress?.city ||
				!newAddress?.location?.lat ||
				!newAddress?.location?.lng)
		) {
			notification.warn({
				message: Strings.sidebar.orders,
				description: Strings.orders.addressMandatory,
				placement: "bottomRight",
				duration: 5,
			})

			return false
		}

		if (!userAddress && !newAddressSelected) {
			notification.warn({
				message: Strings.sidebar.orders,
				description: Strings.orders.selectShippingAddress,
				placement: "bottomRight",
				duration: 5,
			})

			return false
		}

		return true
	}

	breadcrumb() {
		const { dispatch, match } = this.props
		const {
			params: { id },
		} = match || {}
		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.orders,
							route: "/orders",
							icon: "order",
						},
						{
							text: this.state.orderId,
							icon: "order",
						},
					],
					actions: [
						{
							type: "select",
							text: Strings.orders.status,
							value: this.state.orderFlow?.status,
							options: this.state.defaultStatus.map((status: any) => ({
								value: status,
								text: this.getStatus(status),
							})),
							onChange: (status: any) => {
								if (status !== this.state.status) {
									this.setState((prevState: any) => ({
										orderFlow: { ...prevState.orderFlow, status },
									}))
								}
							},
							minWidth: 200,
							maxWidth: 200,
							disabled: true,
							showArrow: false,
						},
						{
							type: "button",
							text: Strings.generic.save,
							onClick: () =>
								id === "new" ? this.createNewOrder() : this.saveOrder(),
							disabled: !this.state.hasUnsavedFields,
							className: this.state.hasUnsavedFields
								? "BreadcrumbButtonSuccess"
								: "",
							separator: "left",
							isSave: true,
						},
					],
				}
			})
		)
	}

	getStatus(status: string) {
		switch (status) {
			case "registered":
				return Strings.orders.registered
			case "address_confirmation":
				return Strings.orders.addressConfirmation
			case "picking":
				return Strings.orders.picking
			case "packing":
				return Strings.orders.packing
			case "loading":
				return Strings.orders.loading
			case "on_transit":
				return Strings.orders.on_transit
			case "delivered":
				return Strings.orders.delivered
			default:
				return status
		}
	}

	validateCoords() {
		const { newAddressSelected, newAddress } = this.state

		if (
			newAddressSelected &&
			newAddress?.address?.trim() &&
			newAddress?.postCode?.trim() &&
			newAddress?.city?.trim() &&
			newAddress?.number?.trim() &&
			newAddress?.floor?.trim() &&
			(!newAddress?.location?.lat || !newAddress?.location?.lng)
		) {
			this.getCoords()
		}
	}

	validateAddress() {
		const { newAddressSelected, newAddress } = this.state

		if (
			newAddressSelected &&
			!newAddress?.address?.trim() &&
			!newAddress?.postCode?.trim() &&
			!newAddress?.city?.trim() &&
			newAddress?.location?.lat &&
			newAddress?.location?.lng
		) {
			this.getAddress()
		}
	}

	renderGeneralInfo() {
		const {
			orderId,
			orderDate,
			slot,
			serviceType,
			itemsQuantity,
			totalWeight,
			totalVolume,
			orderFlow,
			order,
		} = this.state
		const { from, to } = slot || {}

		return (
			<ContentWrapper>
				<Col xs={24}>
					<h2 className="TitleSeparator">
						{Strings.generic.generalInformation}
					</h2>
				</Col>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel">
							{Strings.orders.orderId}
						</label>
						<Input
							id="order_id"
							value={orderId || "-"}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_date" className="InputLabel">
							{Strings.orders.orderDate}
						</label>
						<Input
							id="order_date"
							value={
								orderDate ? moment.utc(orderDate).format("DD-MM-YYYY") : ""
							}
							readOnly
							placeholder={Strings.orders.orderDate}
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="delivery_service_Type" className="InputLabel">
							{Strings.orders.serviceType}
						</label>
						<Select
							className="address_select"
							style={{ width: "100%" }}
							placeholder={Strings.orders.userAddress}
							showSearch
							disabled={false}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={serviceType?.toLowerCase()}
							id="delivery_service_Type"
							onChange={(elem: any) => {
								this.setState({
									serviceType: elem,
									hasUnsavedFields: true,
								})
							}}
						>
							<Select.Option value="normal">
								{Strings.orders.normal}
							</Select.Option>
						</Select>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="delivery_date" className="InputLabel">
							{Strings.orders.deliveryDate}
						</label>
						<DatePicker
							id="delivery_date"
							style={{ width: "100%" }}
							placeholder={Strings.orders.deliveryDate}
							value={
								(orderFlow?.deliveryDate &&
									moment.utc(orderFlow?.deliveryDate)) ||
								null
							}
							format="DD-MM-YYYY"
							inputReadOnly
							allowClear={false}
							disabled={
								orderFlow?.status !== "address_confirmation" &&
								orderFlow?.status !== "registered"
							}
							onChange={(value: any) =>
								this.setState((prevState: any) => ({
									orderFlow: { ...prevState.orderFlow, deliveryDate: value },
									hasUnsavedFields: true,
								}))
							}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label htmlFor="delivery_slot" className="InputLabel">
							{Strings.orders.slot}
						</label>
						<Input
							id="delivery_slot"
							value={
								(from &&
									to &&
									`${Duration.fromMillis(from).toFormat("hh:mm")} - ${Duration.fromMillis(
										to
									).toFormat("hh:mm")}`) ||
								"-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="item_quantity" className="InputLabel">
							{Strings.orders.itemsQuantity}
						</label>
						<Input
							id="item_quantity"
							value={itemsQuantity || ""}
							placeholder={Strings.orders.itemsQuantity}
							type="number"
							onChange={(e: any) =>
								this.setState({
									itemsQuantity: e.target.value,
									hasUnsavedFields: true,
								})
							}
							min={1}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="total_weight" className="InputLabel">
							{Strings.orders.totalWeight}
						</label>
						<Input
							id="total_weight"
							value={totalWeight || ""}
							placeholder={Strings.orders.totalWeight}
							type="number"
							onChange={(e: any) =>
								this.setState({
									totalWeight: e.target.value,
									hasUnsavedFields: true,
								})
							}
							step="0.001"
							suffix="Kg"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="total_volume" className="InputLabel">
							{Strings.orders.totalVolume}
						</label>
						<Input
							id="total_volume"
							value={totalVolume || ""}
							placeholder={Strings.orders.totalVolume}
							onChange={(e: any) =>
								this.setState({
									totalVolume: e.target.value,
									hasUnsavedFields: true,
								})
							}
							step="0.001"
							suffix="cm³"
						/>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderNewOrderInfo() {
		const {
			orderId,
			orderDate,
			slot,
			serviceType,
			itemsQuantity,
			totalWeight,
			totalVolume,
			orderFlow,
			shifts,
			shiftSelected,
			deliveryDate,
		} = this.state
		const { from, to } = slot || {}

		return (
			<ContentWrapper>
				<Col xs={24}>
					<h2 className="TitleSeparator">{"Informação Encomenda"}</h2>
				</Col>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel --label-required">
							{Strings.orders.orderId}
						</label>
						<Input
							id="order_id"
							placeholder={Strings.orders.orderId}
							value={orderId}
							onChange={(event: any) =>
								this.setState({
									orderId: event.target.value,
									hasUnsavedFields: true,
								})
							}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_date" className="InputLabel --label-required">
							{Strings.orders.orderDate}
						</label>
						<DatePicker
							id="delivery_date"
							style={{ width: "100%" }}
							placeholder={Strings.orders.orderDate}
							value={orderDate ? moment.utc(orderDate) : null}
							format="DD-MM-YYYY"
							allowClear={false}
							onChange={(value: any) =>
								this.setState({
									orderDate: value,
									hasUnsavedFields: true,
								})
							}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label
							htmlFor="delivery_service_Type"
							className="InputLabel --label-required"
						>
							{Strings.orders.serviceType}
						</label>
						<Select
							className="address_select"
							style={{ width: "100%" }}
							placeholder={Strings.orders.serviceType}
							showSearch
							disabled={false}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={serviceType?.toLowerCase()}
							id="delivery_service_Type"
							onChange={(elem: any) => {
								this.setState({
									serviceType: elem,
									hasUnsavedFields: true,
								})
							}}
						>
							<Select.Option value="normal">
								{Strings.orders.normal}
							</Select.Option>
						</Select>
					</Col>
					<Col xs={24} md={12}>
						<label
							htmlFor="delivery_date"
							className="InputLabel --label-required"
						>
							{Strings.orders.deliveryDate}
						</label>
						<DatePicker
							id="delivery_date"
							style={{ width: "100%" }}
							placeholder={Strings.orders.deliveryDate}
							value={deliveryDate}
							format="DD-MM-YYYY"
							allowClear={false}
							onChange={(value: any) =>
								this.setState({
									deliveryDate: value,
									hasUnsavedFields: true,
								})
							}
						/>
					</Col>
					<Col xs={24} md={12}>
						<label
							htmlFor="delivery_slot"
							className="InputLabel --label-required"
						>
							{Strings.orders.slot}
						</label>
						<Input
							id="delivery_slot"
							value={this.state.newSlot}
							placeholder="HH:mm-HH:mm"
							onChange={(event) => {
								let inputValue = event.target.value

								// Remove any non-digit characters
								inputValue = inputValue.replace(/\D/g, "")

								// Automatically add ":" after the first 2 characters
								if (inputValue.length >= 2) {
									inputValue =
										inputValue.substring(0, 2) + ":" + inputValue.substring(2)
								}

								// Add "-" after the first 5 characters
								if (inputValue.length >= 5) {
									inputValue =
										inputValue.substring(0, 5) + "-" + inputValue.substring(5)
								}

								// Automatically add ":" in the second "HH:mm"
								if (inputValue.length >= 8) {
									inputValue =
										inputValue.substring(0, 8) + ":" + inputValue.substring(8)
								}

								// Limit the total length
								if (inputValue.length <= 11) {
									this.setState({ newSlot: inputValue })
								}
							}}
							onKeyDown={(event) => {
								// Check if the pressed key is 'Backspace'
								if (event.key === "Backspace" || event.key === "Delete") {
									// Prevent the default 'Backspace' behavior
									event.preventDefault()

									// If there is a selection, remove the selection
									if (
										//@ts-ignore
										document.activeElement.selectionStart !==
										//@ts-ignore
										document.activeElement.selectionEnd
									) {
										//@ts-ignore
										const { selectionStart, selectionEnd } =
											document.activeElement
										const prefix = this.state.newSlot.slice(0, selectionStart)
										const suffix = this.state.newSlot.slice(selectionEnd)
										this.setState({
											newSlot: prefix + suffix,
										})
									} else {
										// Otherwise, remove the previous character
										this.setState((prevState: any) => ({
											newSlot: prevState.newSlot.slice(0, -1),
										}))
									}

									// Update the state by removing the last character
									this.setState((prevState: any) => ({
										newSlot: prevState.newSlot.slice(0, -1),
									}))
								}
							}}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="item_quantity" className="InputLabel">
							{Strings.orders.itemsQuantity}
						</label>
						<Input
							id="item_quantity"
							value={itemsQuantity || ""}
							placeholder={Strings.orders.itemsQuantity}
							type="number"
							onChange={(e: any) =>
								this.setState({
									itemsQuantity: e.target.value,
									hasUnsavedFields: true,
								})
							}
							min={1}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="total_weight" className="InputLabel">
							{Strings.orders.totalWeight}
						</label>
						<Input
							id="total_weight"
							value={totalWeight || ""}
							placeholder={Strings.orders.totalWeight}
							type="number"
							onChange={(e: any) =>
								this.setState({
									totalWeight: e.target.value,
									hasUnsavedFields: true,
								})
							}
							step="0.001"
							suffix="Kg"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="total_volume" className="InputLabel">
							{Strings.orders.totalVolume}
						</label>
						<Input
							id="total_volume"
							value={totalVolume || ""}
							placeholder={Strings.orders.totalVolume}
							onChange={(e: any) =>
								this.setState({
									totalVolume: e.target.value,
									hasUnsavedFields: true,
								})
							}
							step="0.001"
							suffix="cm³"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="total_volume" className="InputLabel">
							{Strings.orders.marketPlaceCode}
						</label>
						<Input
							id="marketplace_code"
							value={this.state.marketPlaceCode}
							placeholder={Strings.orders.marketPlaceCode}
							onChange={(e: any) =>
								this.setState({
									marketPlaceCode: e.target.value,
									hasUnsavedFields: true,
								})
							}
						/>
					</Col>
					<Col xs={24} md={8}>
						<label
							htmlFor="delivery_service_Type"
							className="InputLabel --label-required"
						>
							{Strings.fields.shift}
						</label>
						<Select
							//className="shift_select"
							style={{ width: "100%" }}
							placeholder={Strings.fields.shift}
							//showSearch
							showArrow={true}
							disabled={false}
							filterOption={(input: any, option: any) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={shiftSelected}
							id="drawer_packaging_ype"
							onChange={(elem: any) => {
								this.setState({ selectedShift: elem })
							}}
						>
							{shifts?.map((elem: any) => {
								return (
									<Select.Option value={elem._id}>{elem.name}</Select.Option>
								)
							})}
						</Select>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="lot" className="InputLabel">
							{Strings.orders.lot}
						</label>
						<Input
							id="lot"
							value={this.state.lot}
							placeholder={Strings.orders.lot}
							onChange={(e: any) =>
								this.setState({ lot: e.target.value, hasUnsavedFields: true })
							}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderOrderAddresses() {
		const { orderAddress, formattedAddress, newAddressSelected, newAddress } =
			this.state

		return (
			<ContentWrapper>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={12}>
						<h2 className="TitleSeparator">{Strings.fields.address}</h2>
						<Row gutter={[20, 10]}>
							<Col xs={24}>
								<label htmlFor="order_address" className="InputLabel">
									{Strings.orders.orderAddress}
								</label>
								<Input
									id="order_address"
									value={orderAddress?.address || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="order_post_code" className="InputLabel">
									{Strings.fields.zipCode}
								</label>
								<Input
									id="order_post_code"
									value={orderAddress?.postCode || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="order_city" className="InputLabel">
									{Strings.fields.city}
								</label>
								<Input
									id="order_city"
									value={orderAddress?.city || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={24} md={12}>
						<h2 className="TitleSeparator">
							{Strings.orders.formattedOrderAddress}
						</h2>
						<Row gutter={[20, 10]}>
							<Col xs={18}>
								<label htmlFor="order_formatted_address" className="InputLabel">
									{Strings.orders.orderAddress}
								</label>
								<Input
									id="order_formatted_address"
									value={formattedAddress?.address || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
							<Col xs={6}>
								<Button
									type="primary"
									style={{ marginTop: 30 }}
									block
									disabled={!newAddressSelected}
									onClick={() =>
										this.setState(
											{
												newAddress: {
													address: formattedAddress?.address,
													postCode: formattedAddress?.postCode,
													city: formattedAddress?.city,
													location: {
														lat: formattedAddress?.location?.lat,
														lng: formattedAddress?.location?.lng,
													},
													searchAddress: formattedAddress?.address,
												},
												hasUnsavedFields: true,
											},
											() => this.searchAddress(formattedAddress?.address)
										)
									}
								>
									{Strings.generic.clone}
								</Button>
							</Col>
							<Col xs={12}>
								<label
									htmlFor="order_formatted_post_code"
									className="InputLabel"
								>
									{Strings.fields.zipCode}
								</label>
								<Input
									id="order_formatted_post_code"
									value={formattedAddress?.postCode || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="order_formatted_city" className="InputLabel">
									{Strings.fields.city}
								</label>
								<Input
									id="order_formatted_city"
									value={formattedAddress?.city || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="order_user_lat" className="InputLabel">
									{Strings.orders.latitude}
								</label>
								<Input
									id="order_user_lat"
									value={formattedAddress?.location?.lat.toFixed(7) || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
							<Col xs={12}>
								<label htmlFor="order_user_long" className="InputLabel">
									{Strings.orders.longitude}
								</label>
								<Input
									id="order_user_long"
									value={formattedAddress?.location?.lng?.toFixed(7) || "-"}
									readOnly
									className="OrderInputReadOnly"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderClientAddresses() {
		const {
			formattedAddress,
			userAddress,
			defaultAddresses,
			newAddressSelected,
			newAddress,
			newCoords,
			user,
			phone,
			email,
			basePoint,
			searchAddress = "",
		} = this.state

		const selectedAddress = userAddress
			? defaultAddresses.find((entry: any) => entry._id === userAddress)
			: null
		let coords = {} as any

		if (selectedAddress) {
			if (newAddressSelected) {
				coords.lng = newAddress.location?.lng
				coords.lat = newAddress.location?.lat
			} else {
				if (newCoords) {
					coords.lng = newCoords?.lng
					coords.lat = newCoords?.lat
				} else {
					coords.lng = selectedAddress.location?.lng
					coords.lat = selectedAddress.location?.lat
				}
			}
		} else if (
			formattedAddress?.location?.lat &&
			formattedAddress?.location?.lng
		) {
			coords.lng = formattedAddress.location?.lng
			coords.lat = formattedAddress.location?.lat
		} else {
			coords.lng = null
			coords.lat = null
		}

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.businesses.business}</h2>

				<Col xs={24}>
					<Row gutter={[20, 10]}>
						<Col xs={12}>
							<label htmlFor="business" className="InputLabel">
								{Strings.businesses.business}
							</label>
							<Input
								id="business"
								value={user?.name || "-"}
								readOnly
								className="OrderInputReadOnly"
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="phone" className="InputLabel">
								{Strings.fields.phone}
							</label>
							<Input
								id="phone"
								value={phone || user?.phone || ""}
								placeholder={Strings.fields.phone}
								onChange={(e: any) =>
									this.setState({
										phone: e.target.value,
										hasUnsavedFields: true,
									})
								}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="email" className="InputLabel">
								{Strings.fields.email}
							</label>
							<Input
								id="email"
								value={user?.email || ""}
								placeholder={Strings.fields.email}
								onChange={(e: any) =>
									this.setState({
										email: e.target.value,
										hasUnsavedFields: true,
									})
								}
							/>
						</Col>
					</Row>
					<Row gutter={[20, 10]} style={{ marginTop: "20px" }}>
						<Col xs={12}>
							<Row gutter={[20, 10]}>
								<Col xs={24}>
									<label
										htmlFor="address_select"
										className="InputLabel --label-required"
									>
										{Strings.orders.userAddress}
									</label>
									<Select
										className="address_select"
										style={{ width: "100%" }}
										placeholder={Strings.orders.userAddress}
										showSearch
										disabled={false}
										filterOption={(input: any, option: any) =>
											option.children
												.toLowerCase()
												.indexOf(input.toLowerCase()) >= 0
										}
										value={userAddress}
										onChange={(elem: any) => {
											if (elem === "new_address") {
												this.setState({
													userAddress: elem,
													newAddressSelected: true,
													hasUnsavedFields: true,
													newCoords: null,
												})
											} else {
												this.setState({
													userAddress: elem,
													newAddressSelected: false,
													newAddress: { location: { lat: null, lng: null } },
													hasUnsavedFields: true,
												})
											}
										}}
									>
										{defaultAddresses.map((address: any, index: number) => {
											return (
												<Select.Option
													key={`address_${index}`}
													value={address._id}
												>
													{address.address}
												</Select.Option>
											)
										})}
									</Select>
								</Col>
								<Col xs={24}>
									<label
										htmlFor="order_user_address"
										className={`InputLabel${
											newAddressSelected ? " --label-required" : ""
										}`}
									>
										{Strings.orders.orderAddress}
									</label>
									<Input
										id="order_user_address"
										value={
											(newAddressSelected
												? newAddress?.address
												: selectedAddress?.address) || ""
										}
										placeholder={Strings.orders.orderAddress}
										readOnly={!newAddressSelected}
										className={!newAddressSelected ? "OrderInputReadOnly" : ""}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState(
												(prevState: any) => ({
													newAddress: {
														...prevState.newAddress,
														address: value,
													},
													hasUnsavedFields: true,
													searchAddress: value,
												}),
												() => {
													clearTimeout(this.searchTimer)
													this.searchTimer = setTimeout(() => {
														if (!this.state.searchAddress) return
														this.searchAddress(searchAddress)
													}, 500)
												}
											)
										}}
										onBlur={(e: any) => {
											this.validateCoords()
											if (this.state.searchAddress) {
												this.searchAddress(this.state.searchAddress)
											}
										}}
										onKeyDown={(e: any) => {
											if (e.key === "Enter") {
												this.searchAddress(this.state.searchAddress)
											}
										}}
									/>
								</Col>
								<Col xs={24} md={12}>
									<label
										htmlFor="order_user_address_number"
										//className={`InputLabel${newAddressSelected ? " --label-required" : ""}`}
										className={`InputLabel`}
									>
										{Strings.orders.addressNumber}
									</label>
									<Input
										id="order_user_address_number"
										value={
											(newAddressSelected
												? newAddress?.number
												: selectedAddress?.number) || ""
										}
										placeholder={Strings.orders.addressNumber}
										readOnly={!newAddressSelected}
										className={!newAddressSelected ? "OrderInputReadOnly" : ""}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState((prevState: any) => ({
												newAddress: { ...prevState.newAddress, number: value },
												hasUnsavedFields: true,
											}))
										}}
										onBlur={() => this.validateCoords()}
									/>
								</Col>
								<Col xs={24} md={12}>
									<label
										htmlFor="order_user_address_floor"
										//className={`InputLabel${newAddressSelected ? " --label-required" : ""}`}
										className={`InputLabel`}
									>
										{Strings.orders.addressFloor}
									</label>
									<Input
										id="order_user_address_floor"
										value={
											(newAddressSelected
												? newAddress?.floor
												: selectedAddress?.floor) || ""
										}
										placeholder={Strings.orders.addressFloor}
										readOnly={!newAddressSelected}
										className={!newAddressSelected ? "OrderInputReadOnly" : ""}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState((prevState: any) => ({
												newAddress: { ...prevState.newAddress, floor: value },
												hasUnsavedFields: true,
											}))
										}}
										onBlur={() => this.validateCoords()}
									/>
								</Col>
								<Col xs={12}>
									<label
										htmlFor="order_user_post_code"
										className={`InputLabel${
											newAddressSelected ? " --label-required" : ""
										}`}
									>
										{Strings.fields.zipCode}
									</label>
									<Input
										id="order_user_post_code"
										value={
											(newAddressSelected
												? newAddress?.postCode
												: selectedAddress?.postCode) || ""
										}
										placeholder={Strings.fields.zipCode}
										readOnly={!newAddressSelected}
										className={!newAddressSelected ? "OrderInputReadOnly" : ""}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState((prevState: any) => ({
												newAddress: {
													...prevState.newAddress,
													postCode: value,
												},
												hasUnsavedFields: true,
											}))
										}}
										onBlur={() => this.validateCoords()}
									/>
								</Col>
								<Col xs={12}>
									<label
										htmlFor="order_user_city"
										className={`InputLabel${
											newAddressSelected ? " --label-required" : ""
										}`}
									>
										{Strings.fields.city}
									</label>
									<Input
										id="order_user_city"
										value={
											(newAddressSelected
												? newAddress?.city
												: selectedAddress?.city) || ""
										}
										placeholder={Strings.fields.city}
										readOnly={!newAddressSelected}
										className={!newAddressSelected ? "OrderInputReadOnly" : ""}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState((prevState: any) => ({
												newAddress: { ...prevState.newAddress, city: value },
												hasUnsavedFields: true,
											}))
										}}
										onBlur={() => this.validateCoords()}
									/>
								</Col>
								<Col xs={12}>
									<label
										htmlFor="order_user_lat"
										className={
											newAddressSelected
												? "InputLabel --label-required"
												: "InputLabel"
										}
									>
										{Strings.orders.latitude}
									</label>
									<Input
										id="order_user_lat"
										value={
											newAddressSelected
												? newAddress?.location?.lat
												: selectedAddress?.location?.lat?.toFixed(7)
										}
										placeholder={Strings.orders.latitude}
										// readOnly

										onChange={(e: any) => {
											const value = e.target.value

											this.setState((prevState: any) => ({
												newAddress: {
													...prevState.newAddress,
													location: {
														lat: value,
														lng: prevState.newAddress?.location.lng,
													},
												},
												hasUnsavedFields: true,
											}))
										}}
										readOnly={!newAddressSelected}
										className={
											newAddressSelected ? "OrderInput" : "OrderInputReadOnly"
										}
									/>
								</Col>
								<Col xs={12}>
									<label
										htmlFor="order_user_long"
										className={
											newAddressSelected
												? "InputLabel --label-required"
												: "InputLabel"
										}
									>
										{Strings.orders.longitude}
									</label>
									<Input
										id="order_user_long"
										value={
											newAddressSelected
												? newAddress?.location?.lng
												: selectedAddress?.location?.lng?.toFixed(7)
										}
										placeholder={Strings.orders.latitude}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState((prevState: any) => ({
												newAddress: {
													...prevState.newAddress,
													location: {
														lat: prevState.newAddress?.location.lat,
														lng: value,
													},
												},
												hasUnsavedFields: true,
											}))
										}}
										readOnly={!newAddressSelected}
										className={
											newAddressSelected ? "OrderInput" : "OrderInputReadOnly"
										}
									/>
								</Col>
							</Row>
						</Col>

						<Col xs={12}>
							<Map
								location={
									(basePoint?.coordinates?.length && {
										lat: basePoint?.coordinates?.[1],
										lng: basePoint?.coordinates?.[0],
									}) ||
									{}
								}
								//order //to block the marker movement
								parent={this}
								editingStyle={null}
								editingLocation="location"
								defaultCenter={{ lat: 38.72152, lng: -9.142161 }}
								center={
									(basePoint?.coordinates?.length && {
										lat: basePoint?.coordinates?.[1],
										lng: basePoint?.coordinates?.[0],
									}) ||
									null
								}
								isMarkerShown
								zoom={12}
								googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
								loadingElement={<div style={{ height: "100%" }} />}
								containerElement={
									<div style={{ height: "360px", marginTop: 30 }} />
								}
								mapElement={<div style={{ height: "100%" }} />}
							/>
						</Col>
					</Row>
				</Col>
			</ContentWrapper>
		)
	}

	async getClient(clientId: any) {
		const { dispatch } = this.props

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.get({
				url: Endpoints.uriUserFromClientId(clientId),
			})

			if (response.ok) {
				const { name, phone, email, addresses } = response.data?.results?.user
				this.setState({
					newClientName: name,
					newClientEmail: email,
					phone: phone,
					searchAddress: addresses[addresses.length - 1].postCode
						? `${addresses[addresses.length - 1].address}, ${
								addresses[addresses.length - 1].postCode
							}`
						: addresses[addresses.length - 1].address,
				})
			} else {
				notification.error({
					message: Strings.sidebar.orders,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})

				//dispatch(push("/orders"));
			}
		} catch (err) {
			notification.error({
				message: Strings.serverErrors.title,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})

			//dispatch(push("/orders"));
		}

		dispatch(setLoader(false))
	}

	renderNewClientInfo() {
		const {
			formattedAddress,
			userAddress,
			defaultAddresses,
			newAddressSelected,
			newAddress,
			newCoords,
			user,
			phone,
			email,
			basePoint,
			searchAddress = "",
			newClientId,
			newClientName,
			newClientAddress,
			newClientEmail,
		} = this.state

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{"Informação Cliente"}</h2>

				<Col xs={24}>
					<Row gutter={[20, 10]}>
						<Col xs={7}>
							<label htmlFor="business" className="InputLabel">
								{Strings.orders.clientId}
							</label>
							<Input
								id="business"
								placeholder={Strings.orders.clientId}
								value={newClientId}
								onChange={(e: any) =>
									this.setState({ newClientId: e.target.value })
								}
							/>
						</Col>
						<Col xs={5}>
							<Button
								type="primary"
								style={{ marginTop: 30 }}
								block
								disabled={newClientId?.length === 0}
								onClick={() => this.getClient(newClientId)}
							>
								{"Obter Morada"}
							</Button>
						</Col>

						<Col xs={12}>
							<label htmlFor="business" className="InputLabel">
								{Strings.businesses.business}
							</label>
							<Input
								id="business"
								value={newClientName}
								placeholder={Strings.businesses.business}
								onChange={(e: any) =>
									this.setState({
										newClientName: e.target.value,
										hasUnsavedFields: true,
									})
								}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="phone" className="InputLabel">
								{Strings.fields.phone}
							</label>
							<Input
								id="phone"
								value={phone}
								placeholder={Strings.fields.phone}
								onChange={(e: any) =>
									this.setState({
										phone: e.target.value,
										hasUnsavedFields: true,
									})
								}
							/>
						</Col>
						<Col xs={12}>
							<label htmlFor="email" className="InputLabel">
								{Strings.fields.email}
							</label>
							<Input
								id="email"
								value={email}
								placeholder={Strings.fields.email}
								onChange={(e: any) => {
									console.log(
										"🚀 ~ file: index.tsx:1632 ~ OrderDetail ~ renderNewClientInfo ~ e:",
										e.target.value
									)
									this.setState({
										email: e.target.value,
										hasUnsavedFields: true,
									})
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[20, 10]} style={{ marginTop: "20px" }}>
						<Col xs={24}>
							<Row gutter={[20, 10]}>
								<Col xs={24}>
									<label
										htmlFor="order_user_address"
										className={`InputLabel --label-required`}
									>
										{Strings.orders.orderAddress}
									</label>
									<Input
										id="order_user_address"
										value={searchAddress}
										placeholder={Strings.orders.orderAddress}
										onChange={(e: any) => {
											const value = e.target.value

											this.setState(
												(prevState: any) => ({
													newAddress: {
														...prevState.newAddress,
														address: value,
													},
													hasUnsavedFields: true,
													searchAddress: value,
												}),
												() => {
													clearTimeout(this.searchTimer)
													this.searchTimer = setTimeout(() => {
														if (!this.state.searchAddress) return
														this.searchAddress(searchAddress)
													}, 500)
												}
											)
										}}
										onBlur={(e: any) => {
											this.validateCoords()
											if (this.state.searchAddress) {
												this.searchAddress(this.state.searchAddress)
											}
										}}
										onKeyDown={(e: any) => {
											if (e.key === "Enter") {
												this.searchAddress(this.state.searchAddress)
											}
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Col xs={24}>
						<Map
							location={
								(basePoint?.coordinates?.length && {
									lat: basePoint?.coordinates?.[1],
									lng: basePoint?.coordinates?.[0],
								}) ||
								{}
							}
							//order //to block the marker movement
							parent={this}
							editingStyle={null}
							editingLocation="location"
							defaultCenter={{ lat: 38.72152, lng: -9.142161 }}
							center={
								(basePoint?.coordinates?.length && {
									lat: basePoint?.coordinates?.[1],
									lng: basePoint?.coordinates?.[0],
								}) ||
								null
							}
							isMarkerShown
							zoom={12}
							googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
							loadingElement={<div style={{ height: "100%" }} />}
							containerElement={
								<div style={{ height: "360px", marginTop: 30 }} />
							}
							mapElement={<div style={{ height: "100%" }} />}
						/>
					</Col>
				</Col>
			</ContentWrapper>
		)
	}

	getError(error: string) {
		let description: any

		switch (error) {
			case "date":
				description = Strings.orders.validationDateError
				description = description.split(":")?.[1]
				return { title: Strings.fields.date, description }
			case "postCode":
				description = Strings.orders.validationPostCodeError
				description = description.split(":")?.[1]
				return { title: Strings.fields.zipCode, description }
			case "address":
				description = Strings.orders.validationAddressError
				description = description.split(":")?.[1]
				return { title: Strings.fields.address, description }
			case "coordinates":
				description = Strings.orders.validationCoordinatesError
				description = description.split(":")?.[1]
				return { title: Strings.orders.coordinates, description }
			default:
				return { title: Strings.generic.error }
		}
	}

	renderOperationsBlock() {
		const { orderId, orderRoute, order, slot, orderFlow } = this.state
		const { from, to } = slot || {}
		let addressDetails = orderRoute?.addressDetails
		let boxes = addressDetails?.boxes

		return (
			<ContentWrapper>
				<Col xs={24}>
					<h2 className="TitleSeparator">{Strings.orders.operationsBlock}</h2>
				</Col>
				<Row gutter={[20, 10]}>
					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel">
							{Strings.orders.deliveryDate}
						</label>
						<Input
							id="order_id"
							value={
								order?.orderFlow?.deliveryDate
									? moment
											.utc(order?.orderFlow?.deliveryDate)
											.format("DD/MM/YYYY")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>

					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel">
							{`${Strings.orders.estimatedTimeArrival} (ETA)`}
						</label>
						<Input
							id="order_id"
							value={
								addressDetails?.estimatedTimeArrival
									? moment
											.utc(addressDetails?.estimatedTimeArrival)
											.format("HH:mm")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel">
							{`${Strings.orders.estimatedTimeDeparture} (ETD)`}
						</label>
						<Input
							id="order_id"
							value={
								addressDetails?.estimatedTimeDeparture
									? moment
											.utc(addressDetails?.estimatedTimeDeparture)
											.format("HH:mm")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel">
							{`${Strings.orders.deliveredDate}`}
						</label>
						<Input
							id="order_id"
							value={
								order?.orderFlow?.deliveredDate
									? moment
											.utc(order?.orderFlow?.deliveredDate)
											.format("DD/MM/YYYY")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_date" className="InputLabel">
							{Strings.orders.arrivalTime}
						</label>
						<Input
							id="order_date"
							value={
								addressDetails?.arrivalTime
									? moment
											.utc(addressDetails?.arrivalTime)
											.tz("Europe/Lisbon")
											.format("HH:mm")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_date" className="InputLabel">
							{Strings.orders.departureTime}
						</label>
						<Input
							id="order_date"
							value={
								addressDetails?.departureTime
									? moment
											.utc(addressDetails?.departureTime)
											.tz("Europe/Lisbon")
											.format("HH:mm")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>
					<Col xs={24} md={8}>
						<label htmlFor="order_id" className="InputLabel">
							{`${Strings.orders.deliveredTime}`}
						</label>
						<Input
							id="order_id"
							value={
								order?.orderFlow?.deliveredDate
									? moment
											.utc(order?.orderFlow?.deliveredDate)
											.tz("Europe/Lisbon")
											.format("HH:mm")
									: "-"
							}
							readOnly
							className="OrderInputReadOnly"
						/>
					</Col>

					<Col xs={24} md={8}></Col>
					<Col xs={24} md={8}></Col>
					<Col xs={24} md={12}>
						<label htmlFor="delivery_slot" className="InputLabel">
							{`${Strings.orders.boxes} (${boxes ? boxes?.length : 0}):`}
						</label>
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							{boxes?.length > 0 ? (
								boxes?.map((item: any, index: any) => (
									<div key={item?.boxId} style={{ whiteSpace: "nowrap" }}>
										{item?.bagId ? (
											index === boxes.length - 1 ? (
												<p>{`${item?.boxId} - ${Strings.orders.bag} ${item?.bagId}`}</p>
											) : (
												<p>{`${item?.boxId} - ${Strings.orders.bag} ${item?.bagId};\u00A0`}</p>
											)
										) : index === boxes.length - 1 ? (
											<p>{`${item?.boxId}`}</p>
										) : (
											<p>{`${item?.boxId};\u00A0`}</p>
										)}
									</div>
								))
							) : (
								<div>
									<p>{Strings.orders.noBoxes}</p>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	renderErrors() {
		const { orderErrors } = this.state

		const hasErrors =
			orderErrors?.date ||
			orderErrors?.postCode ||
			orderErrors?.address ||
			orderErrors?.coordinates
		const errors = [] as any

		for (const error in orderErrors) {
			if (orderErrors[error]) {
				const { title, description } = this.getError(error)

				errors.push(
					<div className="ErrorEntry" key={`error_${error}`}>
						<p className="ErrorTitle">{title}</p>
						{description ? <li>{description}</li> : null}
					</div>
				)
			}
		}

		return (
			<ContentWrapper extraClass="OrderErrors">
				<h2 className="TitleSeparator">{Strings.orders.orderErrors}</h2>
				{hasErrors ? errors : <p>{Strings.orders.noErrors}</p>}
			</ContentWrapper>
		)
	}

	renderOrderLogs() {
		const { order } = this.state
		if (order?.flow?.length === 0 || !order?.flow) return null

		let sortedFlows = order?.flow.reverse()

		return (
			<ContentWrapper>
				<h2 className="TitleSeparator">{Strings.orders.orderLogs}</h2>
				<Table
					title={""}
					data={sortedFlows}
					columns={[
						{
							Header: Strings.orders.previousStatus,
							id: "previous_status",
							accessor: (row: any) => this.getStatusName(row.previousStatus),
							//maxWidth: 120,
						},
						{
							Header: Strings.orders.nextStatus,
							id: "next_status",
							accessor: (row: any) => this.getStatusName(row.nextStatus),
							//maxWidth: 120,
						},
						{
							Header: Strings.fields.date,
							id: "log_date",
							accessor: (row: any) =>
								moment
									.utc(row.date)
									.tz("Europe/Lisbon")
									.format("DD/MM/YYYY HH:mm"),
							//maxWidth: 120,
						},
						{
							Header: Strings.orders.staff,
							id: "log_staff",
							accessor: (row: any) => row.staff?.name,
							//maxWidth: 120,
						},
					]}
					// fullPage
					// isSinglePage
					// sortable
					// paginationApi={this.pagination}
					// filtrationApi={this.filtration}
					// onRowPress={this.onPress}
				/>
			</ContentWrapper>
		)
	}

	renderDeliveryInfo() {
		let { order } = this.state

		if (order?.attachments?.length == 0 && !order?.notes) return null

		return (
			<ContentWrapper extraClass="OrderErrors">
				<h2 className="TitleSeparator">{Strings.orders.orderDeliveryInfo}</h2>
				{order?.orderFlow?.status === "not_delivered" && (
					<Row gutter={[20, 10]}>
						<Col xs={24} lg={6}>
							<label htmlFor="not_delivered_reason" className="InputLabel">
								{Strings.orders.notDeliveredReason}
							</label>
							<Input
								id="order_date"
								value={order?.notDeliveredReason}
								readOnly
								className="OrderInputReadOnly"
								style={{ marginBottom: "20px" }}
							/>
						</Col>
					</Row>
				)}
				<Row gutter={[20, 10]}>
					<Col xs={24} lg={6}>
						<label htmlFor="item_notes" className="InputLabel">
							{Strings.orders.notes}
						</label>
						<TextArea
							id="order_notes"
							value={order?.notes || ""}
							readOnly
							className="OrderInputReadOnlyNotes"
						/>
					</Col>
					<Col xs={24} lg={18} className="GalleryContainer">
						{!!order?.attachments?.length && (
							<Fragment>
								<label htmlFor="item_notes" className="InputLabel">
									{Strings.orders.images}
								</label>
								<Image.PreviewGroup>
									<div className="ImageGalleryImageContainer">
										{order?.attachments.map((attach: any) => (
											<div className="ImageWrapper">
												<Image width={200} height={200} src={attach.url} />
											</div>
										))}
									</div>
								</Image.PreviewGroup>
							</Fragment>
						)}
					</Col>
				</Row>
			</ContentWrapper>
		)
	}

	render() {
		const { orderId } = this.state
		const { dispatch, match } = this.props
		const {
			params: { id },
		} = match || {}

		let isUpdateOrder = id !== "new"

		return (
			<div className="ScreenOrder">
				<Helmet>
					<title>
						{Strings.sidebar.orders}
						{orderId ? ` - ${orderId}` : ""}
					</title>
					<meta
						name="description"
						content="Manage your orders and their status"
					/>
				</Helmet>
				{isUpdateOrder ? this.renderGeneralInfo() : this.renderNewOrderInfo()}
				{isUpdateOrder && this.renderOrderAddresses()}
				{isUpdateOrder
					? this.renderClientAddresses()
					: this.renderNewClientInfo()}
				{isUpdateOrder && this.renderOperationsBlock()}
				{this.renderErrors()}
				{isUpdateOrder && this.renderOrderLogs()}
				{isUpdateOrder && this.renderDeliveryInfo()}
			</div>
		)
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
	orderFilters: state.orderFilters,
})

export default connect(mapStateToProps)(OrderDetail)
