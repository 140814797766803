/*
 *
 * ErrorBoundary
 *
 */

import React from "react"
import "./styles.scss"

export default class ErrorBoundary extends React.Component<any, any> {
	constructor(props: any) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		// Sentry
	}

	render() {
		if (this.state.hasError) {
			return <h1>Something went wrong.</h1>
		}

		return this.props.children
	}
}
