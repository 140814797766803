/*
 *
 * EmailTemplates
 *
 */

import React from "react"
import { connect } from "react-redux"
import {
	setTitle,
	delayedDispatch,
	setBreadcrumb,
	updateCrumb,
	setLoader,
} from "store/actions"
import { Helmet } from "react-helmet"
import { Icon, Table } from "components"
import {
	Col,
	Drawer,
	Input,
	InputNumber,
	notification,
	Row,
	Select,
} from "antd"
import Strings from "utils/strings"

import { API, Endpoints } from "utils/api"

class Vehicles extends React.Component<any, any> {
	constructor(props: any) {
		super(props)

		this.state = {
			vehicles: [],
		}
	}

	componentDidMount() {
		const { dispatch } = this.props

		dispatch(setTitle(""))

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.settings,
							route: "/settings",
							icon: "preferences",
						},
						{
							text: Strings.settings.vehicles,
							icon: "delivery-truck",
						},
					],
				}
			})
		)

		this.getData()
	}

	componentDidUpdate() {
		const { dispatch } = this.props
		dispatch(updateCrumb())
	}

	async getData() {
		const { dispatch } = this.props

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.get({
				url: Endpoints.uriVehicles(),
			})

			if (response.ok) {
				const { vehicles = [] } = response.data.results || {}
				this.setState({ vehicles })
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async saveVehicle() {
		const { tempVehicle } = this.state
		const { dispatch } = this.props

		if (!tempVehicle) return

		if (!this.validVehicle()) return

		dispatch(setLoader(true))

		let response: any
		try {
			const request = tempVehicle?._id ? API.put : API.post
			response = await request({
				url: Endpoints.uriVehicles(tempVehicle?._id || ""),
				data: {
					name: tempVehicle.name,
					brand: tempVehicle.brand,
					model: tempVehicle.model,
					year: tempVehicle.year,
					color: tempVehicle.color,
					type: tempVehicle.type,
					licensePlate: tempVehicle.licensePlate,
					capacity: Number(tempVehicle.capacity),
					pricePerMonth: tempVehicle.pricePerMonth,
					typeOfContract: tempVehicle.typeOfContract,
					contractStartDate: tempVehicle.contractStartDate,
					contractEndDate: tempVehicle.contractEndDate,
					expectedWorkingHours: tempVehicle.expectedWorkingHours,
				},
			})

			if (response.ok) {
				await this.getData()
				this.setState({ tempVehicle: null, showDrawer: false })

				notification.success({
					message: Strings.settings.vehicles,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async patchVehicle(vehicle: any) {
		const { dispatch } = this.props

		if (!vehicle?._id) return

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.patch({
				url: Endpoints.uriVehicles(vehicle._id),
				data: {
					isActive: !vehicle.isActive,
				},
			})

			if (response.ok) {
				await this.getData()

				notification.success({
					message: Strings.settings.vehicles,
					description: response.data?.message,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	async archiveVehicle(vehicle: any) {
		const { dispatch } = this.props

		if (!vehicle) return

		dispatch(setLoader(true))

		let response: any
		try {
			response = await API.delete({
				url: Endpoints.uriVehicles(vehicle),
			})

			if (response.ok) {
				await this.getData()

				notification.success({
					message: Strings.settings.vehicles,
					description: Strings.vehicles.archived,
					placement: "bottomRight",
					duration: 5,
				})
			} else {
				notification.error({
					message: Strings.settings.vehicles,
					description:
						response?.data?.message || Strings.serverErrors.wentWrong,
					placement: "bottomRight",
					duration: 5,
				})
			}
		} catch (err) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.serverErrors.wentWrong,
				placement: "bottomRight",
				duration: 5,
			})
		}

		dispatch(setLoader(false))
	}

	validVehicle() {
		const { tempVehicle } = this.state

		if (!tempVehicle?.licensePlate) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.licensePlateRequired,
				placement: "bottomRight",
				duration: 5,
			})
			return false
		}

		if (tempVehicle?.capacity == null || tempVehicle?.capacity < 0) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.capacityRequired,
				placement: "bottomRight",
				duration: 5,
			})
			return false
		}

		if (!tempVehicle?.name) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.nameRequired,
				placement: "bottomRight",
				duration: 5,
			})
			return false
		}

		if (!tempVehicle?.type) {
			notification.error({
				message: Strings.settings.vehicles,
				description: Strings.settings.typeRequired,
				placement: "bottomRight",
				duration: 5,
			})
			return false
		}

		return true
	}

	getType(type: string) {
		switch (type) {
			case "van":
				return Strings.settings.van
			case "bike":
				return Strings.settings.bike
			default:
				return type
		}
	}

	renderTable() {
		const { vehicles = [] } = this.state

		return (
			<Table
				title={{
					icon: "delivery-truck",
					title: Strings.settings.vehicles,
				}}
				data={vehicles}
				columns={[
					{
						Header: Strings.fields.name,
						id: "vehicle_name",
						accessor: (row: any) => row.name || "-",
					},
					{
						Header: Strings.vehicles.vehicleType,
						id: "vehicle_type",
						accessor: (row: any) => this.getType(row.type) || "-",
					},
					{
						Header: Strings.vehicles.licensePlate,
						id: "license_plate",
						accessor: (row: any) => row.licensePlate || "-",
					},
					{
						Header: Strings.vehicles.capacity,
						id: "vehicle_capacity",
						accessor: (row: any) =>
							row.capacity != null
								? `${row.capacity} ${Strings.settings.boxes}`
								: "-",
					},
				]}
				fullPage
				isSinglePage
				filterable
				sortable
				add={{
					onClick: () => this.setState({ showDrawer: true }),
				}}
				actions={{
					edit: (row: any) => ({
						onClick: () =>
							this.setState({
								showDrawer: true,
								tempVehicle: JSON.parse(JSON.stringify(row)),
							}),
					}),
					remove: (row: any) => ({
						onClick: () => this.archiveVehicle(row._id),
					}),
					toggle: (row: any) => ({
						onChange: () => this.patchVehicle(row),
						value: row.isActive,
					}),
				}}
			/>
		)
	}

	renderDrawer() {
		const { showDrawer, tempVehicle } = this.state
		const { mobile } = this.props

		return (
			<Drawer
				title={
					<div className="SidebarTitleContainer">
						<Icon name="delivery-truck" />
						<p>
							{tempVehicle?._id
								? Strings.settings.editVehicle
								: Strings.settings.addVehicle}
						</p>
					</div>
				}
				footer={
					<div className="SidebarFooterContainer">
						<button
							type="button"
							className="SidebarFooterButton --button-confirm"
							onClick={() => this.saveVehicle()}
						>
							{Strings.generic.confirm}
						</button>
						<button
							type="button"
							className="SidebarFooterButton --button-cancel"
							onClick={() =>
								this.setState({
									showDrawer: false,
									tempVehicle: null,
								})
							}
						>
							{Strings.generic.cancel}
						</button>
					</div>
				}
				placement="right"
				width={mobile ? "100%" : 400}
				onClose={() => this.setState({ tempVehicle: null, showDrawer: false })}
				visible={showDrawer}
			>
				{this.renderDrawerContent()}
			</Drawer>
		)
	}

	renderDrawerContent() {
		const { tempVehicle } = this.state

		return (
			<Row gutter={[0, 10]}>
				<Col xs={24}>
					<label htmlFor="vehicle_name" className="InputLabel --label-required">
						{Strings.fields.name}
					</label>
					<Input
						id="vehicle_name"
						placeholder={Strings.fields.name}
						value={tempVehicle?.name || ""}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									name: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_brand" className="InputLabel">
						{Strings.vehicles.brand}
					</label>
					<Input
						id="vehicle_brand"
						placeholder={Strings.vehicles.brand}
						value={tempVehicle?.brand || ""}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									brand: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_model" className="InputLabel">
						{Strings.vehicles.model}
					</label>
					<Input
						id="vehicle_model"
						placeholder={Strings.vehicles.model}
						value={tempVehicle?.model || ""}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									model: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_year" className="InputLabel">
						{Strings.vehicles.year}
					</label>
					<Input
						id="vehicle_year"
						placeholder={Strings.vehicles.year}
						value={tempVehicle?.year || ""}
						type="number"
						min={0}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									year: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_color" className="InputLabel">
						{Strings.vehicles.color}
					</label>
					<Input
						id="vehicle_color"
						placeholder={Strings.vehicles.color}
						value={tempVehicle?.color || ""}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									color: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_type" className="InputLabel --label-required">
						{Strings.settings.vehicleType}
					</label>
					<Select
						style={{ width: "100%" }}
						placeholder={Strings.settings.vehicleType}
						showSearch
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={tempVehicle?.type || null}
						onChange={(value: any) => {
							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									type: value,
								},
							}))
						}}
					>
						<Select.Option value="van">{Strings.settings.van}</Select.Option>
						<Select.Option value="bike">{Strings.settings.bike}</Select.Option>
					</Select>
				</Col>
				<Col xs={24}>
					<label
						htmlFor="vehicle_license_plate"
						className="InputLabel --label-required"
					>
						{Strings.settings.licensePlate}
					</label>
					<Input
						id="vehicle_license_plate"
						placeholder={Strings.settings.licensePlate}
						value={tempVehicle?.licensePlate || ""}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									licensePlate: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label
						htmlFor="vehicle_capacity"
						className="InputLabel --label-required"
					>
						{Strings.settings.capacity}
					</label>
					<Input
						id="vehicle_capacity"
						placeholder={Strings.settings.capacity}
						value={tempVehicle?.capacity || ""}
						type="number"
						min={0}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									capacity: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_price_per_month" className="InputLabel">
						{Strings.vehicles.pricePerMonth}
					</label>
					<Input
						id="vehicle_price_per_month"
						placeholder={Strings.vehicles.pricePerMonth}
						value={tempVehicle?.pricePerMonth || ""}
						type="number"
						min={0}
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									pricePerMonth: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_type_of_contract" className="InputLabel">
						{Strings.vehicles.typeOfContract}
					</label>
					<Select
						style={{ width: "100%" }}
						placeholder={Strings.vehicles.typeOfContract}
						showSearch
						filterOption={(input: any, option: any) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
						value={tempVehicle?.typeOfContract || null}
						onChange={(value: any) => {
							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									typeOfContract: value,
								},
							}))
						}}
					>
						<Select.Option value="paid">
							{Strings.vehicles.contractPaid}
						</Select.Option>
						<Select.Option value="paying">
							{Strings.vehicles.contractPaying}
						</Select.Option>
						<Select.Option value="renting">
							{Strings.vehicles.contractRenting}
						</Select.Option>
						<Select.Option value="leasing">
							{Strings.vehicles.contractLeasing}
						</Select.Option>
					</Select>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_contract_start_date" className="InputLabel">
						{Strings.vehicles.contractStartDate}
					</label>
					<Input
						id="vehicle_contract_start_date"
						placeholder={Strings.vehicles.contractStartDate}
						value={tempVehicle?.contractStartDate || ""}
						type="date"
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									contractStartDate: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label htmlFor="vehicle_contract_end_date" className="InputLabel">
						{Strings.vehicles.contractEndDate}
					</label>
					<Input
						id="vehicle_contract_end_date"
						placeholder={Strings.vehicles.contractEndDate}
						value={tempVehicle?.contractEndDate || ""}
						type="date"
						onChange={(e: any) => {
							const value = e.target.value

							this.setState((prevState: any) => ({
								tempVehicle: {
									...prevState.tempVehicle,
									contractEndDate: value,
								},
							}))
						}}
					/>
				</Col>
				<Col xs={24}>
					<label
						htmlFor="vehicle_expected_working_hours"
						className="InputLabel"
					>
						{Strings.vehicles.expectedWorkingHours}
					</label>
					<div>
						<InputNumber
							id="vehicle_expected_working_hours"
							style={{ width: "100%" }}
							placeholder={Strings.vehicles.expectedWorkingHours}
							value={tempVehicle?.expectedWorkingHours}
							type="number"
							min={0}
							max={24}
							onChange={(value: number) => {
								this.setState((prevState: any) => ({
									tempVehicle: {
										...prevState.tempVehicle,
										expectedWorkingHours: value,
									},
								}))
							}}
						/>
					</div>
				</Col>
			</Row>
		)
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.settings.vehicles}</title>
					<meta
						name="description"
						content={Strings.settings.vehiclesDescription}
					/>
				</Helmet>
				{this.renderTable()}
				{this.renderDrawer()}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state: any) => ({
	language: state.language,
	mobile: state.mobile,
})

export default connect(mapStateToProps)(Vehicles)
