// Generic
export const SET_LOADER = "SET_LOADER"
export const SET_TITLE = "SET_TITLE"
export const SET_USER = "SET_USER"
export const SET_TOKEN = "SET_TOKEN"
export const SET_LOGOUT = "SET_LOGOUT"
export const SET_STAFF_DIALOG = "SET_STAFF_DIALOG"
export const SET_BREADCRUMB = "SET_BREADCRUMB"
export const UPDATE_CRUMB = "UPDATE_CRUMB"
export const SET_UPDATER = "SET_UPDATER"
export const SET_LOGS_FILTERS = "SET_LOGS_FILTERS"
export const SET_ORDER_FILTERS = "SET_ORDER_FILTERS"
export const SET_LANGUAGE = "SET_LANGUAGE"
export const SET_PAGE_SIZE = "SET_PAGE_SIZE"
